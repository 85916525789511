// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-partners-js": () => import("./../../../src/pages/about/partners.js" /* webpackChunkName: "component---src-pages-about-partners-js" */),
  "component---src-pages-about-team-js": () => import("./../../../src/pages/about/team.js" /* webpackChunkName: "component---src-pages-about-team-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-reflections-js": () => import("./../../../src/pages/reflections.js" /* webpackChunkName: "component---src-pages-reflections-js" */),
  "component---src-templates-basic-page-js": () => import("./../../../src/templates/basic-page.js" /* webpackChunkName: "component---src-templates-basic-page-js" */),
  "component---src-templates-custom-page-js": () => import("./../../../src/templates/custom-page.js" /* webpackChunkName: "component---src-templates-custom-page-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-reflection-js": () => import("./../../../src/templates/reflection.js" /* webpackChunkName: "component---src-templates-reflection-js" */),
  "component---src-templates-team-member-js": () => import("./../../../src/templates/team-member.js" /* webpackChunkName: "component---src-templates-team-member-js" */)
}

